import type { FC } from 'react';
import React, { useState, useCallback, useContext } from 'react';

import Popup from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { LoadableAfterPaint } from '@confluence/loadable';
import { START } from '@confluence/navdex';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { usePageSpaceKey } from '@confluence/page-context';
import { useIsEligibleForSurfaceImport } from '@confluence/import-from-sources';

import { useSpaceEnabledContentTypes } from '../useSpaceEnabledContentTypes';

import { GlobalCreateContentButton } from './GlobalCreateContentButton';

type GlobalCreateContentPopoverType = {
	isWhiteboardSpotlightEnabled?: boolean;
};

export const GlobalCreateContentPopover: FC<GlobalCreateContentPopoverType> = ({
	isWhiteboardSpotlightEnabled,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [spaceKey] = usePageSpaceKey();
	const { match } = useContext(RoutesContext);
	const isOnEditRoute = useIsEditorPage();
	const isImportSourcesEnabled = useIsEligibleForSurfaceImport();

	const onClick = useCallback(() => {
		setIsOpen(!isOpen);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'globalCreateContentPopover',
				source: 'globalNavigation',
				attributes: {
					selectedItemPageContext: match?.name,
					navigationLayer: 'global',
					navVersion: '3',
					navdexPointType: START,
					isOnEditRoute,
				},
			},
		}).fire();
	}, [setIsOpen, isOpen, createAnalyticsEvent, match, isOnEditRoute]);

	const {
		isCreateWhiteboardEnabled,
		isCreateBlogEnabled,
		isCreateDatabaseEnabled,
		isCreateSmartLinkEnabled,
	} = useSpaceEnabledContentTypes(spaceKey);

	const popupContent = useCallback(
		() => (
			<GlobalCreateContentPopoverMenuLoader
				closePopup={() => setIsOpen(false)}
				isCreateBlogEnabled={isCreateBlogEnabled}
				isCreateWhiteboardEnabled={isCreateWhiteboardEnabled}
				isCreateDatabaseEnabled={isCreateDatabaseEnabled}
				isCreateSmartLinkEnabled={isCreateSmartLinkEnabled}
				isImportSourcesEnabled={isImportSourcesEnabled}
			/>
		),
		[
			isCreateBlogEnabled,
			isCreateWhiteboardEnabled,
			isCreateDatabaseEnabled,
			isCreateSmartLinkEnabled,
			isImportSourcesEnabled,
		],
	);

	const popupTrigger = useCallback(
		(triggerProps: any) => (
			<>
				<SpotlightTarget name="space-types-changeboarding-target">
					<GlobalCreateContentButton
						onClick={onClick}
						triggerProps={triggerProps}
						isWhiteboardSpotlightEnabled={isWhiteboardSpotlightEnabled}
					/>
				</SpotlightTarget>
			</>
		),
		[onClick, isWhiteboardSpotlightEnabled],
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom-start"
			content={popupContent}
			trigger={popupTrigger}
			testId="create-content-popover"
			shouldRenderToParent
		/>
	);
};

const GlobalCreateContentPopoverMenuLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-GlobalCreateContentPopoverMenu" */ './GlobalCreateContentPopoverMenu'
			)
		).GlobalCreateContentPopoverMenu,
	_noPreloadWhenRenderingSPA: true,
});
